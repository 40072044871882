.navbar {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    list-style-type: none;
    margin: 0;
    padding: 10px;
    overflow: hidden;
    background-color: #333;
}

.navbar-link a {
    display: block;
    color: white;
    /*text-align: center;*/
    padding: 14px 16px;
    text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
.navbar-link a:hover {
    background-color: #111;
}
