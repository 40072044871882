.App {
    text-align: center;
}

h1 {
    padding: 10px;
    text-align: center;
}

.content-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.content {
    padding: 20px;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
}