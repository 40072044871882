h1 {
    padding: 10px;
    text-align: center;
}

.inputForm {
    display: inline-grid;
}

.clintInputMode {
    align-items: center;
    justify-content: center;
}

.clintInputForm {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 173px;
    padding-bottom: 173px;
    box-sizing: content-box;
    border: 400px !important;
}

.patientForm {
    text-align: center;
}

input {
    margin: 5px;
    text-align: center;
    font-family: Gotham, serif, "Yu Gothic";
    color: white;
    background-color: #303245;
    border: 0;
    border-color: rgb(0, 136, 178);
    margin: 5px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0px;
    width: 100%;
    border-radius: 100px;
    border: 6px solid transparent;
}

input[type=file] {
    border-radius: 100px;
}

select {
    margin: 5px;
    text-align: center;
    font-family: Gotham, serif, "Yu Gothic";
    color: white;
    background-color: #303245;
    border: 0;
    border-color: rgb(0, 136, 178);
    margin: 5px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0px;
    width: 100%;
    border-radius: 100px;
    border: 6px solid transparent;
}

button {
    align-items: center;
    appearance: none;
    background-color: rgb(31, 151, 140);
    border-radius: 24px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px, rgba(0, 0, 0, .14) 0 6px 10px 0, rgba(0, 0, 0, .12) 0 1px 18px 0;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-size: 17px;
    height: 48px;
    justify-content: center;
    letter-spacing: .25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    padding: 2px 24px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1), opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: auto;
    will-change: transform, opacity;
    z-index: 0;
}

button:hover {
    background: #F6F9FE;
    color: #174ea6;
    transition: all 250ms ease 0s;
    transition-duration: 250ms;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: all;
    cursor: pointer;
    transform: scale(0.999);
}

button:active {
    box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
    outline: none;
}

button:focus {
    outline: none;
    border: 2px solid #4285f4;
}

button:not(:disabled) {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

button:not(:disabled):hover {
    box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

button:not(:disabled):focus {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

button:not(:disabled):active {
    box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

button:disabled {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
}